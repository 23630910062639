/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"single_closed_door",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class SingleClosedDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "single_closed_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 18,
			h: 5,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M48,22v4c0,.553-.447,1-1,1H5c-.553,0-1-.447-1-1v-4c0-.553.447-1,1-1h42c.553,0,1,.447,1,1ZM1.55,23.17c-.05-.04-.11-.07-.17-.09-.061-.03-.12-.05-.19-.061-.12-.029-.26-.029-.39,0-.06.011-.12.03-.18.061-.061.02-.12.05-.181.09l-.149.12c-.04.05-.09.1-.12.149-.04.061-.07.12-.09.181-.03.06-.05.12-.061.18-.01.07-.02.14-.02.2,0,.27.1.52.29.71.1.09.21.16.33.21s.25.08.38.08.26-.03.38-.08.229-.12.33-.21c.189-.19.29-.44.29-.71s-.11-.521-.29-.71c-.05-.04-.101-.08-.16-.12Z" fill={fill} /></svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
