/* eslint-disable max-len */
import React from "react";

interface Props {
	fill: string;
}

export default function BuffetIcon({ fill }: Props) {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M37,6.05v-1.05c0-.55-.45-1-1-1s-1,.45-1,1v1.05c-5.05.5-9,4.77-9,9.95h20c0-5.18-3.95-9.45-9-9.95ZM13,6.05v-1.05c0-.55-.45-1-1-1s-1,.45-1,1v1.05c-5.05.5-9,4.77-9,9.95h20c0-5.18-3.95-9.45-9-9.95ZM47.96,20.57c.01-.02,0-.04-.01-.07-.01-.13-.05-.26-.09-.39-.01-.04-.02-.07-.04-.1-.04-.13-.09-.25-.15-.36-.07-.14-.15-.27-.23-.39-.09-.12-.19-.24-.3-.35-.16-.17-.33-.31-.52-.43-.2-.12-.4-.22-.62-.3-.31-.12-.65-.18-1-.18H3c-.35,0-.69.06-1,.18-.22.08-.42.18-.62.3-.19.12-.36.26-.52.43-.11.11-.21.23-.3.35-.08.12-.16.25-.23.39-.06.11-.11.23-.15.36-.02.03-.03.06-.04.1-.04.13-.08.26-.09.39-.01.03-.02.05-.01.07-.03.14-.04.28-.04.43v14c0,.55.45,1,1,1,1.67,0,2.46.41,3.37.89.48.25.99.51,1.63.72v4.39H1c-.55,0-1,.45-1,1s.45,1,1,1h46c.55,0,1-.45,1-1s-.45-1-1-1h-5v-4.39c.64-.21,1.15-.47,1.63-.72.91-.48,1.7-.89,3.37-.89.55,0,1-.45,1-1v-14c0-.15-.01-.29-.04-.43ZM40,42H8v-4.02c.21.01.43.02.66.02,2.16,0,3.3-.59,4.3-1.11.91-.48,1.7-.89,3.37-.89s2.46.41,3.37.89c1,.52,2.13,1.11,4.3,1.11s3.29-.59,4.29-1.11c.91-.48,1.7-.89,3.37-.89s2.46.41,3.37.89c1,.52,2.14,1.11,4.3,1.11.24,0,.46-.01.67-.02v4.02Z"
                fill={fill}
            />
        </svg>
    );
}
