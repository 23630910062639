/* eslint-disable max-len */

export default function DoubleSofaIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M26,16h8c.552,0,1,.449,1,1v3h-10v-3c0-.551.448-1,1-1ZM13,19v1h10v-3c0-.551-.448-1-1-1h-8c-.552,0-1,.449-1,1v2ZM11,31v-11h-1c-.552,0-1,.449-1,1v10c0,.551.448,1,1,1s1-.449,1-1ZM13,30h10v-8h-10v8ZM25,30h10v-8h-10v8ZM39,21c0-.551-.448-1-1-1h-1v11c0,.551.448,1,1,1s1-.449,1-1v-10Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
