/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	getDefaultColorTheme,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"band_element",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

export const BandElementProps: RecordProps<ICustomShape> = {
	w: T.number,
	h: T.number,
	color: DefaultColorStyle as any,
};

// [2]
export class BandElement extends ShapeUtil<ICustomShape> {
	static override type = "band_element" as const;
	static override props = BandElementProps;
	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 34,
			h: 15,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}

	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;
		return (
			<SVGContainer>
				<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M48,33c0,2.7-1.19,5.23-3.28,6.95-1.62,1.34-3.65,2.05-5.74,2.05-.59,0-1.18-.06-1.77-.17-2.01-.39-3.8-1.48-5.09-3-.44-.52-.82-1.08-1.12-1.69-.4-.75-.68-1.56-.84-2.42-.44-2.35.04-4.72,1.33-6.68.44-.66.63-1.48.54-2.35-.02-.23-.03-.46-.03-.69,0-.3.02-.61.06-.9.1-.8.34-1.57.71-2.28.42-.85,1.03-1.61,1.78-2.23.45-.37.93-.67,1.45-.91v12.32c0,1.65,1.35,3,3,3s3-1.35,3-3v-12.33c1.85.86,3.26,2.53,3.78,4.56.22.87.27,1.75.17,2.61-.07.54-.04,1.34.45,2.04,1.05,1.51,1.6,3.28,1.6,5.12ZM37,8h1v23c0,.55.45,1,1,1s1-.45,1-1V8h1c.553,0,1-.448,1-1s-.447-1-1-1h-4c-.553,0-1,.448-1,1s.447,1,1,1ZM29.41,38.41c.31.57.69,1.12,1.13,1.64-1.92,1.26-4.19,1.95-6.54,1.95-2.39,0-4.66-.68-6.6-1.98l-1.69,1.69c-.2.19-.45.29-.71.29s-.51-.1-.71-.29c-.39-.39-.39-1.03,0-1.42l1.53-1.53c-2.44-2.24-3.82-5.38-3.82-8.76,0-4.32,2.29-8.26,6-10.39v-3.61h-1c-.553,0-1-.448-1-1s.447-1,1-1h14c.553,0,1,.448,1,1s-.447,1-1,1h-1v3.61c.43.25.84.52,1.24.83-.08.15-.16.31-.24.46-.24.46-.44.93-.6,1.42-.15.49-.25,1-.32,1.51-.05.37-.08.77-.08,1.17,0,.29.01.58.04.9.04.4-.03.76-.22,1.04-1.58,2.4-2.16,5.3-1.63,8.15.2,1.06.55,2.07,1.02,2.93.06.13.13.26.2.39ZM20,18.68c1.28-.45,2.63-.68,4-.68s2.72.23,4,.68v-2.68h-8v2.68ZM28,30c0-2.21-1.79-4-4-4s-4,1.79-4,4,1.79,4,4,4,4-1.79,4-4ZM24,28c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2ZM7,34.64v-14.64h4c.55,0,1-.45,1-1v-6c0-.55-.45-1-1-1h-4v-2h4c.55,0,1-.45,1-1s-.45-1-1-1H1c-.55,0-1,.45-1,1s.45,1,1,1h4v2H1c-.55,0-1,.45-1,1v6c0,.55.45,1,1,1h4v14.64L.23,40.36c-.35.42-.29,1.05.13,1.41.43.35,1.05.29,1.41-.13l4.23-5.08,4.23,5.08c.2.24.48.36.77.36.23,0,.45-.08.64-.23.42-.36.48-.99.13-1.41l-4.77-5.72Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
