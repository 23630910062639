/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"rectangle_table_10",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class RectangleTable10Element extends ShapeUtil<ICustomShape> {
	static override type = "rectangle_table_10" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 32,
			h: 21,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;
		return (
			<SVGContainer>
				<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M18,8v4c0,.552-.447,1-1,1h-6c-.553,0-1-.448-1-1v-4c0-1.654,1.346-3,3-3h2c1.654,0,3,1.346,3,3ZM20,12c0,.552.447,1,1,1h6c.553,0,1-.448,1-1v-4c0-1.654-1.346-3-3-3h-2c-1.654,0-3,1.346-3,3v4ZM35,5h-2c-1.654,0-3,1.346-3,3v4c0,.552.447,1,1,1h6c.553,0,1-.448,1-1v-4c0-1.654-1.346-3-3-3ZM37,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM27,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM17,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM45,15h-4c-.553,0-1,.448-1,1v6c0,.552.447,1,1,1h4c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3ZM45,25h-4c-.553,0-1,.448-1,1v6c0,.552.447,1,1,1h4c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3ZM7,25H3c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h4c.553,0,1-.448,1-1v-6c0-.552-.447-1-1-1ZM7,15H3c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h4c.553,0,1-.448,1-1v-6c0-.552-.447-1-1-1ZM35,15H13c-1.654,0-3,1.346-3,3v12c0,1.654,1.346,3,3,3h22c1.654,0,3-1.346,3-3v-12c0-1.654-1.346-3-3-3Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
