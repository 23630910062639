/* eslint-disable */
import {
	Geometry2d,
	TLDefaultColorStyle,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	getDefaultColorTheme,
	DefaultColorStyle,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"sofa",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

export const SofaShapeProps: RecordProps<ICustomShape> = {
	w: T.number,
	h: T.number,
	color: DefaultColorStyle as any,
};

// [2]
export class SofaElement extends ShapeUtil<ICustomShape> {
	static override type = "sofa" as const;
	static override props = SofaShapeProps;

	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 42,
			h: 15,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M32,16h8c.552,0,1,.449,1,1v3h-10v-3c0-.551.448-1,1-1ZM19,17v3h10v-3c0-.551-.448-1-1-1h-8c-.552,0-1,.449-1,1ZM7,19v1h10v-3c0-.551-.448-1-1-1h-8c-.552,0-1,.449-1,1v2ZM5,31v-11h-1c-.552,0-1,.449-1,1v10c0,.551.448,1,1,1s1-.449,1-1ZM7,30h10v-8H7v8ZM19,30h10v-8h-10v8ZM31,30h10v-8h-10v8ZM45,21c0-.551-.448-1-1-1h-1v11c0,.551.448,1,1,1s1-.449,1-1v-10Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator(shape: ICustomShape) {
		return <rect width={shape.props.w} height={shape.props.h} />;
	}
}
