/* eslint-disable max-len */
import React from "react";
interface Props {
    fill: string;
}

export default function CakeIcon({ fill }: Props) {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M14.92,8.18c-.32.23-.6.42-.92.57v-2.75c0-1.65,1.35-3,3-3h14c1.65,0,3,1.35,3,3v2.75c-.33-.14-.61-.34-.93-.57-.75-.52-1.68-1.18-3.41-1.18-1.74,0-2.67.66-3.41,1.18-.67.48-1.16.82-2.26.82s-1.58-.34-2.25-.82c-.75-.52-1.68-1.18-3.41-1.18-1.74,0-2.66.66-3.41,1.18ZM35,13h-1v-2.16c-.94-.21-1.56-.65-2.08-1.02-.68-.48-1.16-.82-2.26-.82s-1.59.34-2.26.82c-.75.52-1.68,1.18-3.41,1.18s-2.66-.66-3.41-1.18c-.67-.48-1.15-.82-2.25-.82s-1.59.34-2.26.82c-.52.37-1.14.8-2.07,1.02v2.16h-1c-1.65,0-3,1.35-3,3v1.91c.15.09.28.18.41.27.67.48,1.16.82,2.25.82,1.1,0,1.59-.34,2.26-.82.75-.52,1.67-1.18,3.41-1.18,1.73,0,2.66.66,3.41,1.18.67.48,1.15.82,2.25.82s1.59-.34,2.26-.82c.74-.52,1.67-1.18,3.41-1.18,1.73,0,2.66.66,3.41,1.18.67.48,1.16.82,2.26.82s1.59-.34,2.26-.82c.13-.09.26-.18.41-.28v-1.9c0-1.65-1.35-3-3-3ZM39,23h-1v-2.7c-.64.38-1.46.7-2.67.7-1.74,0-2.67-.66-3.41-1.18-.68-.48-1.16-.82-2.26-.82s-1.59.34-2.26.82c-.75.52-1.68,1.18-3.41,1.18s-2.66-.66-3.41-1.18c-.67-.48-1.15-.82-2.25-.82s-1.59.34-2.26.82c-.74.52-1.67,1.18-3.41,1.18-1.2,0-2.02-.32-2.66-.7v2.7h-1c-1.65,0-3,1.35-3,3v1h1c1.73,0,2.66.66,3.41,1.18.67.48,1.16.82,2.25.82,1.1,0,1.59-.34,2.26-.82.75-.52,1.67-1.18,3.41-1.18,1.73,0,2.66.66,3.41,1.18.67.48,1.15.82,2.25.82s1.59-.34,2.26-.82c.74-.52,1.67-1.18,3.41-1.18,1.73,0,2.66.66,3.41,1.18.67.48,1.16.82,2.26.82s1.59-.34,2.26-.82c.74-.52,1.67-1.18,3.41-1.18h1v-1c0-1.65-1.35-3-3-3ZM42,29h-1c-1.1,0-1.59.34-2.26.82-.75.52-1.68,1.18-3.41,1.18-1.74,0-2.67-.66-3.41-1.18-.68-.48-1.16-.82-2.26-.82s-1.59.34-2.26.82c-.75.52-1.68,1.18-3.41,1.18s-2.66-.66-3.41-1.18c-.67-.48-1.15-.82-2.25-.82s-1.59.34-2.26.82c-.74.52-1.67,1.18-3.41,1.18-1.73,0-2.66-.66-3.41-1.18-.67-.48-1.15-.82-2.25-.82h-1v4h36v-4ZM47.82,35H.18c-.12.31-.18.65-.18,1,0,1.65,1.35,3,3,3h20v4h-10c-.55,0-1,.45-1,1s.45,1,1,1h22c.55,0,1-.45,1-1s-.45-1-1-1h-10v-4h20c1.65,0,3-1.35,3-3,0-.35-.06-.69-.18-1Z"
                fill={fill}
            />
        </svg>
    );
}
