/* eslint-disable max-len */
import React from "react";

interface Props {
    fill: string;
    height?: number;
    width?: number;
}

export default function DrinksIcon({ fill, height = 28, width = 28 }: Props) {
    return (
        <svg id="a" width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M48,19c0,2.21-1.79,4-4,4H4c-2.21,0-4-1.79-4-4s1.79-4,4-4h4v-2.18c-1.16-.42-2-1.52-2-2.82v-2c0-.55.45-1,1-1h4c.55,0,1,.45,1,1v2c0,1.3-.84,2.4-2,2.82v2.18h4v-6c0-1.3.84-2.4,2-2.82v-1.18h-.2c-.55,0-1-.45-1-1s.45-1,1-1h2.4c.55,0,1,.45,1,1s-.45,1-1,1h-.2v1.18c1.16.42,2,1.52,2,2.82v6h4v-2.18c-1.16-.42-2-1.52-2-2.82v-2c0-.55.45-1,1-1h4c.55,0,1,.45,1,1v2c0,1.3-.84,2.4-2,2.82v2.18h18c2.21,0,4,1.79,4,4ZM42,25h2v15c0,2.76-2.24,5-5,5H9c-2.76,0-5-2.24-5-5v-15h38ZM10,38c0,.55.45,1,1,1h26c.55,0,1-.45,1-1v-8c0-.55-.45-1-1-1H11c-.55,0-1,.45-1,1v8ZM36,31H12v6h24v-6Z"
                fill={fill}
            />
        </svg>
    );
}
