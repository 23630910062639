/* eslint-disable max-len */
import React from "react";

interface Props {
	fill: string;
}

export default function DessertIcon({ fill }: Props) {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M15,39.908v-7.908h4.523l-.244.609s0,.002-.001.004l-2.201,5.502c-.368.919-1.147,1.57-2.077,1.794ZM15.974,24h1.026c1.627,0,3.061.793,3.974,2h25.026v-5.039c-1.398-.127-2.278-.567-3.061-.974-.858-.445-1.602-.848-3.142-.787-1.536.042-2.253.468-3.083.96-.934.554-1.992,1.183-4.047,1.24-2.071.045-3.153-.512-4.118-1.013-.858-.445-1.581-.838-3.143-.788-1.54.043-2.26.468-3.093.961-.937.554-1.997,1.182-4.057,1.239-.108.003-.216.005-.321.005-.356,0-.686-.03-1.011-.071-.126.844-.458,1.614-.951,2.266ZM21.677,32h24.323v-4h-24.101c.066.323.101.658.101,1v2c0,.014-.007.025-.008.038-.004.113-.021.225-.064.333l-.007.018s0,0,0,.001l-.244.61ZM30.601,10h2.899c2.415,0,4.435-1.721,4.899-4h-2.899c-2.415,0-4.435,1.721-4.899,4ZM25.5,10h2.899c-.465-2.279-2.484-4-4.899-4h-2.899c.465,2.279,2.484,4,4.899,4ZM12,15v3h4c.553,0,1,.447,1,1v.718c.368.059.755.093,1.198.083,1.546-.043,2.266-.469,3.098-.962.937-.554,1.998-1.182,4.056-1.239,2.08-.036,3.154.512,4.119,1.013.859.446,1.584.844,3.142.788,1.535-.043,2.252-.468,3.082-.96.935-.555,1.993-1.183,4.048-1.24,2.047-.037,3.152.511,4.118,1.013.647.335,1.237.636,2.14.745v-3.958c0-1.654-1.346-3-3-3H15c-1.654,0-3,1.346-3,3ZM7,24h5c1.654,0,3-1.346,3-3v-1h-5c-1.654,0-3,1.346-3,3v1ZM2,30h18v-1c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1ZM7,39.908v-7.908H2.477l2.446,6.114c.368.919,1.147,1.57,2.077,1.794ZM13,40v-8h-4v8h4ZM46,37v-3h-25.123l-1.943,4.856c-.167.418-.393.796-.652,1.144h24.718c1.654,0,3-1.346,3-3Z"
                fill={fill}
            />
        </svg>
    );
}
