/* eslint-disable max-len */

export default function TripleSofaIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M32,16h8c.552,0,1,.449,1,1v3h-10v-3c0-.551.448-1,1-1ZM19,17v3h10v-3c0-.551-.448-1-1-1h-8c-.552,0-1,.449-1,1ZM7,19v1h10v-3c0-.551-.448-1-1-1h-8c-.552,0-1,.449-1,1v2ZM5,31v-11h-1c-.552,0-1,.449-1,1v10c0,.551.448,1,1,1s1-.449,1-1ZM7,30h10v-8H7v8ZM19,30h10v-8h-10v8ZM31,30h10v-8h-10v8ZM45,21c0-.551-.448-1-1-1h-1v11c0,.551.448,1,1,1s1-.449,1-1v-10Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
