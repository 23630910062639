/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"gift_element",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class GiftElement extends ShapeUtil<ICustomShape> {
	static override type = "gift_element" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 34,
			h: 16,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg id="a" width={shape.props.w} height={shape.props.h} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M30,24h16v19c0,2.76-2.24,5-5,5h-11v-24ZM2,43c0,2.76,2.24,5,5,5h12v-24H2v19ZM45,10h-11.54c.96-1.06,1.54-2.46,1.54-4,0-3.31-2.69-6-6-6-2.09,0-3.93,1.07-5,2.69-1.07-1.62-2.91-2.69-5-2.69-3.31,0-6,2.69-6,6,0,1.54.58,2.94,1.54,4H3c-1.65,0-3,1.35-3,3v6c0,1.3.84,2.41,2,2.82v.18h17v-12c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4v4h-2v38h7V10h-3v-4c0-2.21,1.79-4,4-4s4,1.79,4,4c0,1.86-1.27,3.43-3,3.87v12.13h16v-.18c1.16-.41,2-1.52,2-2.82v-6c0-1.65-1.35-3-3-3Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
