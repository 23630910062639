/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"round_table_8",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class RoundTable8Element extends ShapeUtil<ICustomShape> {
	static override type = "round_table_8" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 14,
			h: 14,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg width={shape.props.w} height={shape.props.h} id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M21,8c-.553,0-1-.448-1-1V3c0-1.654,1.346-3,3-3h2c1.654,0,3,1.346,3,3v4c0,.552-.447,1-1,1h-6ZM12,24c0,6.617,5.383,12,12,12s12-5.383,12-12-5.383-12-12-12-12,5.383-12,12ZM9.15,14.808c.188.188.441.293.707.293s.52-.105.707-.293l4.243-4.243c.391-.39.391-1.023,0-1.414l-2.828-2.829c-1.171-1.169-3.074-1.168-4.243,0l-1.414,1.415c-.566.566-.879,1.32-.879,2.121s.312,1.555.879,2.121l2.828,2.829ZM3,28h4c.553,0,1-.448,1-1v-6c0-.552-.447-1-1-1H3c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3ZM9.15,33.192l-2.828,2.828c-.566.567-.879,1.32-.879,2.122s.312,1.555.879,2.121l1.414,1.415h0c.585.585,1.354.877,2.121.877s1.537-.292,2.122-.877l2.828-2.829c.391-.391.391-1.024,0-1.414l-4.243-4.243c-.375-.375-1.039-.375-1.414,0ZM28,41c0-.552-.447-1-1-1h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4ZM38.85,33.192c-.188-.188-.441-.293-.707-.293s-.52.105-.707.293l-4.243,4.243c-.391.39-.391,1.023,0,1.414l2.828,2.829c.585.584,1.354.876,2.121.876s1.537-.292,2.122-.877l1.414-1.415c.566-.566.879-1.32.879-2.121s-.312-1.555-.879-2.121l-2.828-2.829ZM45,20h-4c-.553,0-1,.448-1,1v6c0,.552.447,1,1,1h4c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3ZM33.192,10.565l4.243,4.243c.188.188.441.293.707.293s.52-.105.707-.293l2.828-2.828c.566-.567.879-1.32.879-2.122s-.312-1.555-.879-2.121l-1.414-1.415h0c-1.171-1.17-3.074-1.168-4.243,0l-2.828,2.829c-.391.391-.391,1.024,0,1.414Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
