/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"double_open_door",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class DoubleOpenDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "double_open_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 30,
			h: 16,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg id="a" width={shape.props.w} height={shape.props.h} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M3.979,35.31c.011.061.021.13.021.19,0,.27-.101.52-.29.71-.101.09-.21.16-.33.21s-.25.08-.38.08-.26-.03-.38-.08-.23-.12-.33-.21c-.19-.19-.29-.44-.29-.71,0-.26.1-.521.29-.71.229-.23.58-.34.899-.271.07.011.13.03.19.061.06.02.12.05.17.09.06.03.11.08.16.12.09.1.16.2.21.33.03.06.05.12.06.189ZM47,11.5h-4c-8.859,0-16.38,5.794-19,13.788-2.62-7.994-10.141-13.788-19-13.788H1c-.553,0-1,.447-1,1v19c0,.553.447,1,1,1h4c.553,0,1-.447,1-1V13.551c9.459.524,17,8.362,17,17.949,0,.553.447,1,1,1s1-.447,1-1c0-9.587,7.541-17.426,17-17.949v17.949c0,.553.447,1,1,1h4c.553,0,1-.447,1-1V12.5c0-.553-.447-1-1-1ZM45.56,34.67c-.06-.04-.12-.07-.18-.09-.061-.03-.12-.05-.19-.061-.319-.069-.67.04-.899.271-.181.189-.29.45-.29.71,0,.27.1.52.29.71.189.189.439.29.71.29s.52-.101.71-.29c.189-.19.29-.44.29-.71s-.11-.521-.29-.71c-.05-.04-.101-.09-.15-.12Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
