/* eslint-disable max-len */
export default function StageIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M4,28h4v4h32v-4h4v4h2V3c0-.55-.45-1-1-1H3c-.55,0-1,.45-1,1v29h2v-4ZM40,4h4v4h-4v-4ZM40,10h4v4h-4v-4ZM40,16h4v4h-4v-4ZM40,22h4v4h-4v-4ZM10,21V4h7v2.13c-1.73.44-3,2.01-3,3.87v3c0,.55.45,1,1,1h6c.55,0,1-.45,1-1v-3c0-1.86-1.27-3.43-3-3.87v-2.13h10v2.13c-1.73.44-3,2.01-3,3.87v3c0,.55.45,1,1,1h6c.55,0,1-.45,1-1v-3c0-1.86-1.27-3.43-3-3.87v-2.13h7v18H10v-1ZM18,8c1.1,0,2,.9,2,2v2h-4v-2c0-1.1.9-2,2-2ZM30,8c1.1,0,2,.9,2,2v2h-4v-2c0-1.1.9-2,2-2ZM4,4h4v4h-4v-4ZM4,10h4v4h-4v-4ZM4,16h4v4h-4v-4ZM4,22h4v4h-4v-4ZM30.28,34l3.33,10H14.39l3.33-10h12.56ZM15.61,34l-2,6H0v-6h15.61ZM48,34v6h-13.61l-2-6h15.61Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
