/* eslint-disable max-len */
export default function SingleCloseDoorIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M48,22v4c0,.553-.447,1-1,1H5c-.553,0-1-.447-1-1v-4c0-.553.447-1,1-1h42c.553,0,1,.447,1,1ZM1.55,23.17c-.05-.04-.11-.07-.17-.09-.061-.03-.12-.05-.19-.061-.12-.029-.26-.029-.39,0-.06.011-.12.03-.18.061-.061.02-.12.05-.181.09l-.149.12c-.04.05-.09.1-.12.149-.04.061-.07.12-.09.181-.03.06-.05.12-.061.18-.01.07-.02.14-.02.2,0,.27.1.52.29.71.1.09.21.16.33.21s.25.08.38.08.26-.03.38-.08.229-.12.33-.21c.189-.19.29-.44.29-.71s-.11-.521-.29-.71c-.05-.04-.101-.08-.16-.12Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
