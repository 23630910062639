/* eslint-disable max-len */

export default function SingleOpenDoorIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M3,48c-.271,0-.521-.101-.71-.3-.19-.181-.29-.431-.29-.7,0-.13.03-.26.08-.38.05-.13.12-.23.21-.33.1-.09.2-.16.33-.21.359-.16.81-.07,1.09.21.18.189.29.45.29.71,0,.27-.101.52-.29.7-.101.1-.21.17-.33.22s-.25.08-.38.08ZM5,0H1C.45,0,0,.45,0,1v42c0,.55.45,1,1,1h4c.55,0,1-.45,1-1V2.01c22.15.53,40,18.71,40,40.99,0,.55.45,1,1,1s1-.45,1-1C48,19.29,28.71,0,5,0Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
