/* eslint-disable max-len */

export default function SofaIcon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M19,17c0-.551.448-1,1-1h8c.552,0,1,.449,1,1v3h-10v-3ZM17,31v-11h-1c-.552,0-1,.449-1,1v10c0,.551.448,1,1,1s1-.449,1-1ZM19,30h10v-8h-10v8ZM31,20v11c0,.551.448,1,1,1s1-.449,1-1v-10c0-.551-.448-1-1-1h-1Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
