import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuth from "@/hooks/useAuth";
import { addCustomerToPaystackRequest, sendSignupEmail, uploadProfileImage } from "@/axios/post-request";
import { SocialsList, UserList } from "@/types";
import { updateOnboardingDetails, updateUserDetails } from "@/axios/put-request";
import useUser from "@/hooks/useUser";
import "react-toastify/dist/ReactToastify.css";

type onboardingFormStateType = {
    business_name: string;
    phone_number: string;
    website: string;
    ceremonies: Array<{
        type: string;
        location: string;
        date: Date | null;
        no_of_guests: string;
    }>;
    collaborators?: Array<{ role: string; email: string; image: string; name: string }>;
};
type socialMediaType = Array<{ type: keyof typeof SocialsList; link: string }>;

export default function usePlannerOnboarding(file?: File | null) {
    const { getAuthDetails } = useAuth();
    const [loading, setLoading] = useState(false);
    const { data } = useUser();
    const navigate = useNavigate();

    async function onboardPlannerHandler(onboardingDetails: onboardingFormStateType, socialMedia: socialMediaType) {
        try {
            if (!onboardingDetails.business_name) {
                return toast.error("Business name is required");
            }
            if (onboardingDetails.business_name.length < 3) {
                return toast.error("Please enter a valid business name");
            }
            if (!onboardingDetails.phone_number) {
                return toast.error("Phone number is required");
            }
            if (onboardingDetails.phone_number.length < 9 || onboardingDetails.phone_number.length > 11) {
                return toast.error("Please enter a valid phone number");
            }

            setLoading(true);
            const authDetails = getAuthDetails();
            const userId = authDetails?.currentUser?.uid as string;

            const updateUserRequest = await updateUserDetails(userId, {
                event_ids: [],
                user_type: UserList.planner,
                business_name: onboardingDetails.business_name,
                website: onboardingDetails.website,
                phone_number: onboardingDetails.phone_number,
                action: { signup_email_sent: true },
                name: "",
                social_media: socialMedia,
                tour_guide: {
                    user_profile: false,
                    event_overview: false,
                    budget_tracker: false,
                    checklist: false,
                    floor_plan: false,
                    homepage: false,
                },
            });
            if (file && userId) {
                const formData = new FormData();
                formData.append("file", file);
                await uploadProfileImage(formData, userId);
            }
            if (updateUserRequest.status === 200 && !data?.result?.action?.signup_email_sent) {
                const currentUserEmail = authDetails?.currentUser?.email as string;
                await sendSignupEmail(onboardingDetails.business_name, currentUserEmail);
            }
            await updateOnboardingDetails(userId, {
                onboarding_details: {
                    event_name: "",
                    location: onboardingDetails.ceremonies[0]?.location ?? "",
                    event_date: "",
                    no_of_guests: 0,
                    name: "",
                    business_name: onboardingDetails.business_name,
                    phone_number: onboardingDetails.phone_number,
                    website: onboardingDetails.website,
                    ceremonies: onboardingDetails.ceremonies,
                },
                onboarding_stage: 1,
                completed: false,
            });
            if (authDetails?.currentUser?.email && !data?.result?.paystack_customer_details?.id) {
                await addCustomerToPaystackRequest({ email: authDetails?.currentUser?.email, name: onboardingDetails?.business_name });
            }
            setLoading(false);
            return navigate("/planner/pricing");
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error("An error occured");
        }
    }

    return {
        loading,
        onboardPlannerHandler,
        data,
    };
}
