/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"double_closed_door",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class DoubleClosedDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "double_closed_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 36,
			h: 4,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;
		return (
			<SVGContainer>
				<svg id="a" width={shape.props.w} height={shape.props.h} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M1.979,23.81c.011.061.021.13.021.19,0,.27-.101.52-.29.71-.19.189-.44.29-.71.29s-.521-.101-.71-.29c-.19-.19-.29-.44-.29-.71,0-.26.109-.521.29-.71.37-.37,1.05-.37,1.42,0l.12.149c.04.061.069.12.09.181.03.06.05.12.06.189ZM4,22v4c0,.55.45,1,1,1h18v-6H5c-.55,0-1,.45-1,1ZM43,21h-18v6h18c.55,0,1-.45,1-1v-4c0-.55-.45-1-1-1ZM47.71,23.29c-.101-.09-.21-.16-.33-.21-.24-.11-.521-.11-.76,0-.13.05-.23.12-.33.21-.181.189-.29.45-.29.71,0,.27.1.52.29.71.189.189.439.29.71.29s.52-.101.71-.29c.189-.19.29-.44.29-.71,0-.26-.11-.521-.29-.71Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
