/* eslint-disable max-len */
import React from "react";

interface Props {
    fill: string;
}

export default function CateringIcon({ fill }: Props) {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M48,21v1H0v-1c0-2.44,1.74-4.47,4.05-4.91.42-4.21,3.76-7.57,7.95-8.03v-1.06c0-.55.45-1,1-1s1,.45,1,1v1.06c4.16.46,7.48,3.78,7.94,7.94h7.06c-.63-.83-1-1.87-1-3,0-.55.45-1,1-1-.63-.83-1-1.87-1-3,0-.55.45-1,1-1-.63-.83-1-1.87-1-3,0-.55.45-1,1-1h12c.55,0,1,.45,1,1,0,1.13-.37,2.17-1,3,.55,0,1,.45,1,1,0,1.13-.37,2.17-1,3,.55,0,1,.45,1,1,0,1.13-.37,2.17-1,3h2c2.76,0,5,2.24,5,5ZM0,24v15c0,.55.45,1,1,1,1.31,0,2.25-.22,3-.51.49-.18.91-.4,1.3-.6.24-.12.46-.24.7-.35.54-.25,1.14-.45,2-.51.21-.02.43-.03.67-.03.52,0,.95.04,1.33.11.84.15,1.41.45,2.04.78,1,.52,2.14,1.11,4.3,1.11s3.3-.59,4.3-1.11c.91-.48,1.69-.89,3.36-.89,1.68,0,2.46.41,3.38.89,1,.52,2.13,1.11,4.29,1.11s3.3-.59,4.3-1.11c.63-.33,1.19-.63,2.03-.78.38-.07.82-.11,1.34-.11.24,0,.46.01.66.03.86.06,1.45.25,2,.51.24.11.47.22.71.35.39.2.8.42,1.29.6.75.29,1.69.51,3,.51.55,0,1-.45,1-1v-15H0ZM9.65,40.08c-.29-.05-.61-.08-.98-.08-.17,0-.33.01-.48.02-.07.01-.13.01-.19.02-.51.06-.83.17-1.16.32-.14.06-.26.12-.39.19l-.26.13c-.06.03-.13.06-.19.1-.38.19-.81.4-1.31.59-.2.08-.41.15-.63.21.11.58.39,1.12.81,1.53.56.57,1.32.89,2.13.89,1.65,0,3-1.35,3-3v-.83c-.11-.04-.22-.07-.35-.09ZM43.28,41.36c-.49-.18-.92-.4-1.28-.58-.03-.02-.06-.04-.09-.05l-.11-.06c-.24-.13-.43-.22-.63-.31-.34-.16-.66-.27-1.17-.32-.04,0-.09-.01-.14-.01-.2-.02-.35-.03-.52-.03-.37,0-.7.03-.98.08-.13.02-.25.05-.36.09v.83c0,.81.32,1.57.87,2.11.56.57,1.32.89,2.13.89,1.45,0,2.67-1.04,2.94-2.42-.22-.06-.44-.14-.66-.22Z"
                fill={fill}
            />
        </svg>
    );
}
