/* eslint-disable max-len */

export default function Rectangle10Icon() {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
                d="M18,8v4c0,.552-.447,1-1,1h-6c-.553,0-1-.448-1-1v-4c0-1.654,1.346-3,3-3h2c1.654,0,3,1.346,3,3ZM20,12c0,.552.447,1,1,1h6c.553,0,1-.448,1-1v-4c0-1.654-1.346-3-3-3h-2c-1.654,0-3,1.346-3,3v4ZM35,5h-2c-1.654,0-3,1.346-3,3v4c0,.552.447,1,1,1h6c.553,0,1-.448,1-1v-4c0-1.654-1.346-3-3-3ZM37,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM27,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM17,35h-6c-.553,0-1,.448-1,1v4c0,1.654,1.346,3,3,3h2c1.654,0,3-1.346,3-3v-4c0-.552-.447-1-1-1ZM45,15h-4c-.553,0-1,.448-1,1v6c0,.552.447,1,1,1h4c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3ZM45,25h-4c-.553,0-1,.448-1,1v6c0,.552.447,1,1,1h4c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3ZM7,25H3c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h4c.553,0,1-.448,1-1v-6c0-.552-.447-1-1-1ZM7,15H3c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h4c.553,0,1-.448,1-1v-6c0-.552-.447-1-1-1ZM35,15H13c-1.654,0-3,1.346-3,3v12c0,1.654,1.346,3,3,3h22c1.654,0,3-1.346,3-3v-12c0-1.654-1.346-3-3-3Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
