/* eslint-disable */
import {
	Geometry2d,
	RecordProps,
	Rectangle2d,
	ShapeUtil,
	SVGContainer,
	T,
	TLBaseShape,
	TLResizeInfo,
	resizeBox,
	TLDefaultColorStyle,
	DefaultColorStyle,
	getDefaultColorTheme,
} from "tldraw";
import { TLIconDefaultColor } from "@/helper";

type ICustomShape = TLBaseShape<
	"dj_element",
	{
		w: number;
		h: number;
		color: TLDefaultColorStyle;
	}
>;

// [2]
export class DJElement extends ShapeUtil<ICustomShape> {
	static override type = "dj_element" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: DefaultColorStyle as any,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 34,
			h: 15,
			color: TLIconDefaultColor,
		};
	}

	// [c]
	override canEdit() {
		return true;
	}

	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		const theme = getDefaultColorTheme({ isDarkMode: false });
		const themeColor = theme[shape.props.color] as { solid: string };
		const fill = themeColor.solid;

		return (
			<SVGContainer>
				<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
					<path
						d="M39,4H9C4.04,4,0,8.04,0,13v22c0,4.96,4.04,9,9,9h30c4.96,0,9-4.04,9-9V13c0-4.96-4.04-9-9-9ZM42,35c0,1.65-1.35,3-3,3H9c-1.65,0-3-1.35-3-3V13c0-1.65,1.35-3,3-3h30c1.65,0,3,1.35,3,3v22ZM39,12H9c-.55,0-1,.45-1,1v22c0,.55.45,1,1,1h30c.55,0,1-.45,1-1V13c0-.55-.45-1-1-1ZM25.71,28.29c.18.19.29.44.29.71v4c0,.55-.45,1-1,1s-1-.45-1-1v-3.59l-1.1-1.1c-1.36,1.06-3.05,1.69-4.9,1.69-4.41,0-8-3.59-8-8s3.59-8,8-8,8,3.59,8,8c0,1.85-.63,3.54-1.69,4.9l1.4,1.39ZM31,18h-1v15c0,.55-.45,1-1,1s-1-.45-1-1v-15h-1c-.55,0-1-.45-1-1s.45-1,1-1h1v-1c0-.55.45-1,1-1s1,.45,1,1v1h1c.55,0,1,.45,1,1s-.45,1-1,1ZM37,32h-1v1c0,.55-.45,1-1,1s-1-.45-1-1v-1h-1c-.55,0-1-.45-1-1s.45-1,1-1h1v-15c0-.55.45-1,1-1s1,.45,1,1v15h1c.55,0,1,.45,1,1s-.45,1-1,1ZM18,21c.55,0,1,.45,1,1s-.45,1-1,1-1-.45-1-1,.45-1,1-1ZM24,22c0,1.29-.42,2.49-1.11,3.47l-2.18-2.18h-.01c.19-.39.3-.83.3-1.29,0-1.65-1.35-3-3-3s-3,1.35-3,3,1.35,3,3,3c.46,0,.9-.11,1.29-.3v.01l2.18,2.18c-.98.69-2.18,1.11-3.47,1.11-3.31,0-6-2.69-6-6s2.69-6,6-6,6,2.69,6,6Z"
						fill={fill}
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
