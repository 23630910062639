interface Props {
	text?: string;
	title?: string;
	icon?: JSX.Element;
	description?: string;
}

export default function FloorplanCategoryTextIcon({ title, text, icon, description }: Props) {
    const elementText = text ? text : title;
    return (
        <div className="element_icon">
            <div className="title_wrapper">{icon ? icon : <div className="title">{title}</div>}</div>
            <p>
                {elementText}
                {description && <sup>{description}</sup>}
            </p>
        </div>
    );
}
